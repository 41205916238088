import React from 'react';
import { graphql } from 'gatsby';
import GridPagePart, { Props as GridPagePartProps } from 'src/components/_templateParts/GridPagePart';
import BasicPageFragment_SitePage from 'src/transformers/BasicPageFragment_SitePage';

interface Props {
  data: GatsbyTypes.IndustriesPageQuery,
}

export const query = graphql`
  query IndustriesPage($industriesPageId: String) {
    allWpIndustry {
      nodes {
        ...IndustryBasic
        featuredImage {
          ...FeaturedImageThumbnail
        }
        acfSeo {
          metaDescription
        }
      }
    }
    relatedPage: wpPage(id: {eq: $industriesPageId}) {
      ...BasicPageBasic
    }
  }
`;

const IndustriesPage: React.FC<Props> = ({ data }) => {
  const relatedPage = BasicPageFragment_SitePage(data.relatedPage);
  const gridItems: GridPagePartProps['gridItems'] = data.allWpIndustry.nodes.map((node) => ({
    title: node.title,
    imageData: node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    slug: node.slug,
  }));
  return (
    <GridPagePart
      relatedPage={relatedPage}
      gridItems={gridItems}
    />
  );
};

export default IndustriesPage;
